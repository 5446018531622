<template>
  <nav
    v-if="0 !== breadcrumbs.length"
    class="breadcrumbs"
    aria-label="Breadcrumb"
  >
    <ul class="breadcrumbs__list">
      <li class="breadcrumbs__item">
        <MyLink class="breadcrumbs__link" name="home">
          {{ _T("@Main") }}
        </MyLink>
      </li>
      <li
        v-for="(item, index) in breadcrumbs"
        :key="index"
        class="breadcrumbs__item"
      >
        <Component
          :is="index !== breadcrumbs.length - 1 ? 'MyLink' : 'div'"
          class="breadcrumbs__link"
          :name="item.routeName"
          :params="{ ...item.params }"
        >
          {{
            typeof item.name === "string"
              ? _T(item.name)
              : getLocalizedName(item.name)
          }}
        </Component>
      </li>
    </ul>
  </nav>
</template>

<script setup>
//https://github.com/nuxt/nuxt/issues/25316
const props = defineProps({
  breadcrumbs: { type: Array, required: true, default: () => [] },
});

const getJsonLd = () => {
  let position = 1;

  const itemList = [
    {
      "@type": "ListItem",
      position: position++,
      item: {
        "@id": getUrl(getLocalizedRouteName("home")),
        name: _T("@Trademark"),
      },
    },
  ];

  props.breadcrumbs.forEach((breadcrumb) => {
    itemList.push({
      "@type": "ListItem",
      position: position++,
      item: {
        "@id": getUrl(getLocalizedRouteName(breadcrumb.routeName), {
          ...breadcrumb.params,
        }),
        name: breadcrumb.name,
      },
    });
  });

  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: itemList,
  };
};

useHead({
  script: [
    {
      type: "application/ld+json",
      children: getJsonLd(),
    },
  ],
});
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding: 16px 16px 0;
  margin-bottom: 16px;

  &__list {
    @extend %width-main;

    @include flex-container(row, flex-start);
    flex-wrap: wrap;

    margin-inline: auto;
  }

  &__item {
    &:hover:not(:last-child) {
      color: var(--color-primary-base);
    }

    div:only-of-type {
      cursor: initial;

      &:after {
        content: none;
      }
    }

    @include bigMobile {
      display: none;

      &:nth-last-child(2) {
        display: flex;
      }
    }
  }

  &__link {
    @include flex-container(row, center, center);
    gap: 4px;

    @include font(14, 16, 500);

    padding: 8px;

    &:after {
      content: "";
      background-image: url("assets/icons/arrow-right.svg");
      background-size: 12px 12px;
      background-repeat: no-repeat;
      display: inline-block;
      width: 12px;
      height: 12px;

      @include bigMobile {
        transform: rotateZ(180deg);
      }
    }

    @include bigMobile {
      flex-direction: row-reverse;
    }
  }
}
</style>
